/*
 * @Author: xuyang
 * @Date: 2022-10-14 00:20:57
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-31 20:33:55
 */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Area, Column, Pie } from '@ant-design/charts';
import { INormalList } from '../type';
import axios from '@/utils/request/request';
import { monitorUrls } from '../const';
import moment from 'moment-timezone';
import { customContent, getMax, legend } from '@/utils';
import { useSelector } from 'react-redux';

function BtmInfo({ timeValue }: any) {
  const header = useSelector((state: any) => state.header);
  const [dataCountry, setDataCountry] = useState<Array<INormalList>>([]); // 日活跃店铺数
  const [dataPayType, setDataPayType] = useState<Array<INormalList>>([]); // 日订单数
  // 获取参数
  const getParams = () => ({
    beg: timeValue[0].format('YYYY-MM-DD'),
    end: timeValue[1].format('YYYY-MM-DD')
  });
  // 加载国家
  const loadCountry = () => {
    axios.get(monitorUrls.country, { params: getParams() }).then((data: any) => {
      const as: Array<INormalList> = [];
      data.forEach((item: any) => {
        as.push({
          type: item.ch_name,
          value: item.yzfdd,
          category: '已支付订单',
          title: item.ch_name
        });
        as.push({
          type: item.ch_name,
          value: item.wzfdd,
          category: '未支付订单',
          title: item.ch_name
        });
        as.push({
          type: item.ch_name,
          value: item.zhl,
          category: '转化率',
          title: item.ch_name
        });
      });
      setDataCountry(as);
      return data;
    });
  };
  // 加载订单类型统计
  const loadPayType = () => {
    axios.get(monitorUrls.paytype, { params: getParams() }).then((data: any) => {
      const as: Array<INormalList> = [];
      data.forEach((item: any) => {
        as.push({
          type: item.pay_type,
          value: item.yzfdd,
          // category: '已支付订单',
          title: item.pay_type
        });
        // as.push({
        //   type: item.pay_type,
        //   value: item.wzfdd,
        //   category: '未支付订单',
        //   title: item.pay_type
        // });
        // as.push({
        //   type: item.pay_type,
        //   value: item.zhl,
        //   category: '转化率',
        //   title: item.pay_type
        // });
      });
      setDataPayType(as);
      return data;
    });
  };
  useEffect(() => {
    setTimeout(() => {
      loadCountry();
      loadPayType();
    });
  }, [timeValue]);
  const config = {
    appendPadding: 10,
    data: dataPayType,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    yAxis: {
      max: getMax([...dataPayType])
    },
    statistic: {
      style: {
        fontSize: 80,
        fontWeight: 300,
        textAlign: 'center',
        textBaseline: 'middle',
        shadowColor: 'white',
        shadowBlur: 10
      },
      title: false,
      content: {
        content: ''
      }
    },
    legend: { ...legend }
  };
  const configCountry = {
    data: dataCountry,
    isGroup: true,
    xField: 'type',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      max: getMax([...dataCountry])
    },
    tooltip: {
      customContent: customContent
    },
    legend: { ...legend }
  };
  return (
    <div className="monitor-center">
      {/* 底部内容 */}
      <Row gutter={[16, 16]}>
        {/* 实时订单 */}
        <Col xs={24} lg={12}>
          <div className="chart-main">
            <p className="chart-title">
              <span>支付渠道</span>
            </p>
            <div className="chart-content">
              <Pie {...config} />
            </div>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div className="chart-main">
            <p className="chart-title">
              <span>国家订单</span>
            </p>
            <div className="chart-content">
              <Column {...configCountry} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BtmInfo;
