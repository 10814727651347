/*
 * @Author: xuyang
 * @Date: 2022-09-28 23:30:28
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-28 23:41:32
 * @FilePath: \react_antd\src\components\LeftMenu\config.ts
 * @Description: 配置项
 */
import React from 'react';
import { IMenuList } from './type';
import { HomeOutlined } from '@ant-design/icons';

export const menuList: Array<IMenuList> = [
  {
    key: 'monitor',
    label: '首页',
    icon: '',
    url: '/monitor'
  },
  {
    key: 'trendShop',
    label: '店铺趋势',
    url: '/trend/shop'
  },
  {
    key: 'trendOrder',
    label: '订单趋势',
    url: '/trend/order'
  },
  {
    key: 'trendSales',
    label: '销售数据',
    url: '/trend/sales'
  },
  {
    key: 'trendVisitor',
    label: '访客数据',
    url: '/trend/visiter'
  },
  {
    key: 'shopRank',
    label: '店铺排行',
    icon: '',
    url: '/shopRank'
  },
  {
    key: 'productRank',
    label: '商品销售排行',
    icon: '',
    url: '/productRank'
  },
  {
    key: 'shopSetting',
    label: '统计店铺设置',
    icon: '',
    url: '/shopSetting'
  }
];
