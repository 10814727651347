import React, { FC, useState, useEffect } from 'react';
import { Button, Dropdown, Avatar, Menu, message, Space, Drawer } from 'antd';
import Icon, { LoginOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { IHeaderProps } from './types';
import './index.less';
import { connect, useSelector } from 'react-redux';
import LeftMenu from '../LeftMenu';
import { isMobile } from 'react-device-detect';
import TImeZone from '../TImeZone';
import * as actions from '@/actions';
import { remove } from 'react-cookies';
import { useLocation, useNavigate } from 'react-router-dom';
import Country from './Country';
import Paytype from './Paytype';
import TimeCount from './TimeCount';
interface IUserInfo {
  userName?: string;
  avatar?: string;
}
const Header: FC<IHeaderProps> = (props: IHeaderProps) => {
  const { onSetCollapsed, onShowMenu } = props;
  const header = useSelector((state: any) => state.header);
  const navigate = useNavigate();
  const location: any = useLocation();
  console.log(location, 'location');
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    userName: '',
    avatar: ''
  });
  const [collapsed, setCollapsed] = useState<boolean>(false);
  // 初始加载
  useEffect(() => {
    setUserInfo({
      userName: 'XuXu',
      avatar: ''
    });
  }, []);
  // 菜单事件
  const menuChange = ({ key }: { key: string }): void => {
    if (key === 'modify') {
      message.success('修改密码');
    } else {
      message.success('退出登录');
    }
  };
  // 用户下拉
  const userMenu = (
    <Menu
      onSelect={menuChange}
      items={[
        { label: '修改密码', key: 'modify' },
        { label: '退出登录', key: 'logout' }
      ]}
    ></Menu>
  );
  //菜单栏改变
  const collapsedClick = () => {
    if (isMobile) {
      onShowMenu(true);
    } else {
      onSetCollapsed(!collapsed);
      setCollapsed(!collapsed);
    }
  };
  // 退出， 删除token 跳转登录
  const logout = () => {
    remove('token');
    navigate('login');
  };
  return (
    <div className="header">
      <div className="menu-control">
        <Button type="link" onClick={collapsedClick}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <Space>
          <p className="header-title">{header.title}</p>
          <TImeZone styles={isMobile ? { width: 140, marginLeft: 10 } : { width: 280, marginLeft: 12 }}></TImeZone>
          {!isMobile && <Country />}
          {!isMobile && <Paytype />}
          {!isMobile && (location.pathname === '/monitor' || location.pathname === '/') && <TimeCount />}
        </Space>
      </div>
      <div className="header-control">
        {/* <Button type="link" className="clear" icon={<i className="ico-clear"></i>}>
          清除缓存
        </Button>
        <Button type="link" className="help" icon={<i className="ico-help"></i>}>
          清除缓存
        </Button>
        <Button type="link" className="shop" icon={<i className="ico-help"></i>}>
          进入店铺
        </Button>
        <div className="user-center">
          <Dropdown overlay={userMenu}>
            <div>
              <Avatar className="avatar" src={userInfo.avatar} />
              <span>{userInfo.userName}</span>
            </div>
          </Dropdown>
        </div> */}
        {!isMobile && <LoginOutlined className="logout" onClick={logout} />}
      </div>
    </div>
  );
};

export default connect((state) => state, actions)(Header);
