/*
 * @Author: xuyang
 * @Date: 2022-09-28 22:30:08
 * @LastEditors: xuyang 897103652@qq.com
 * @LastEditTime: 2022-09-28 23:52:07
 * @FilePath: \react_antd\src\components\LeftMenu\index.tsx
 * @Description: 左侧菜单内容
 */
import React, { FC, useEffect, useState } from 'react';
import { Button, Menu } from 'antd';
import { menuList } from './config';
import { useNavigate, useLocation } from 'react-router-dom';
import { IMenuProps } from './type';
import _ from 'lodash';
// import logo from '@/asset/img/icon-03.png';
import { isMobile } from 'react-device-detect';

import './index.less';
const LeftMenu: FC<IMenuProps> = ({ onChange }: IMenuProps) => {
  const [menuKey, setMenuKey] = useState<Array<string>>(['']);
  const navigate = useNavigate();
  // 路由
  const location = useLocation();
  useEffect(() => {
    const item: any = _.find(menuList, { url: location.pathname });
    setMenuKey([item?.key]);
  }, [location]);
  // 菜单栏
  const menuChange = ({ item, key, keyPath }: { item: any; key: string; keyPath: string[] }) => {
    const url = item.props.url;
    navigate(url);
    if (isMobile && onChange) {
      // 移动端隐藏菜单
      onChange(false);
    } else {
      setMenuKey([key]);
    }
  };
  return (
    <div className="left-main">
      <div className="left-box">
        {/* logo */}
        {!isMobile && <div className="logo">shoppaas{/* <img src={logo} /> */}</div>}
        <Menu selectedKeys={menuKey} mode="inline" onSelect={menuChange} theme="light" items={menuList} />
      </div>
      {/* <div className="menu-btns">
        <Button className="btn btn-des" type="link">
          店铺装修
        </Button>
        <Button className="btn btn-set" type="link">
          店铺配置
        </Button>
      </div> */}
    </div>
  );
};

export default LeftMenu;
