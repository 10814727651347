import React, { useEffect, useState } from 'react';
import { Cascader } from 'antd';
import axios from '@/utils/request/request';
import { headerUrls } from './const';
function Country() {
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<string[]>([]);
  const fetchArea = () => {
    axios
      .get(headerUrls.area)
      .then((data: any) => {
        const area = data.map((item: any) => ({
          value: item.id + '',
          label: item.ch_name,
          children: item.childs.map((it: any) => ({
            value: it.id + '',
            label: it.ch_name
          }))
        }));
        setOptions(area);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const onChange = (v: any) => {
    setValue(v);
    if (v) {
      localStorage.setItem('gj', JSON.stringify(v));
    } else {
      localStorage.removeItem('gj');
    }
    window.location.reload();
  };
  useEffect(() => {
    const gj = localStorage.getItem('gj');
    if (gj) {
      console.log(gj);
      try {
        setValue(JSON.parse(gj));
      } catch (e: any) {
        localStorage.setItem('gj', JSON.stringify({}));
      }
    }
  }, [options]);
  useEffect(() => {
    fetchArea();
  }, []);
  return <Cascader placeholder="地区" allowClear options={options} value={value} onChange={onChange}></Cascader>;
}

export default Country;
