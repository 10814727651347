/*
 * @Author: xuyang
 * @Date: 2022-10-14 00:20:57
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-31 20:33:55
 */
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { Area, Column, Line } from '@ant-design/charts';
import { INormalList } from '../type';
import axios from '@/utils/request/request';
import { monitorUrls } from '../const';
import moment from 'moment-timezone';
import { customContent, getMax, legend } from '@/utils';
import { useSelector } from 'react-redux';

function BottomInfo() {
  const header = useSelector((state: any) => state.header);
  const [activeShop, setActiveShop] = useState<Array<INormalList>>([]); // 日活跃店铺数
  const [dayOrders, setDayOrders] = useState<Array<INormalList>>([]); // 日订单数
  const [daySales, setDaySales] = useState<Array<INormalList>>([]); // 日销售额
  // 获取参数
  const [params] = useState({
    beg: moment().subtract(14, 'd').tz(header.timeZone).format('YYYY-MM-DD'),
    end: moment().tz(header.timeZone).format('YYYY-MM-DD')
  });
  // 获取key值
  const getKeys = (list: any) => Object.keys(list).sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
  // 加载日活跃店铺数
  const loadActiveShopData = () => {
    axios.get(monitorUrls.activeShop, { params: params }).then((data: any) => {
      const as: Array<INormalList> = [];
      // 活跃店铺
      const keys = getKeys(data?.hddp);
      const keys1 = getKeys(data?.hddp1);
      keys.forEach((item: string, index: number) => {
        if (item === 'total' || item === 'avg') {
          return;
        }
        const k = item.split('-')[2];
        as.push({
          type: k,
          value: data?.hddp[item],
          category: '本周期',
          title: item
        });
        as.push({
          type: k,
          value: data?.hddp1[keys1[index]],
          category: '环比',
          title: keys1[index]
        });
      });
      setActiveShop(as);
      return data;
    });
  };
  // 加载日订单
  const loadDayOrders = () => {
    axios.get(monitorUrls.dayOrders, { params: params }).then((data: any) => {
      const as: Array<INormalList> = [];
      const keys = getKeys(data?.ddzs);
      const keys1 = getKeys(data?.ddzs1);
      keys.forEach((item: string, index: number) => {
        if (item === 'total' || item === 'avg') {
          return;
        }
        const k = item.split('-')[2];
        // as.push({
        //   type: k,
        //   value: data?.ddzs[item],
        //   category: '订单总数本周期',
        //   title: item
        // });
        // as.push({
        //   type: k,
        //   value: data?.ddzs1[keys1[index]],
        //   category: '订单总数环比',
        //   title: keys[index]
        // });
        // as.push({
        //   type: k,
        //   value: data?.wzfdd[item],
        //   category: '未支付订单本周期',
        //   title: keys[index]
        // });
        // as.push({
        //   type: k,
        //   value: data?.wzfdd1[keys1[index]],
        //   category: '未支付订单环比',
        //   title: keys[index]
        // });
        as.push({
          type: k,
          value: data?.yzfdd[item],
          category: '已支付订单本周期',
          title: item
        });
        as.push({
          type: k,
          value: data?.yzfdd1[keys1[index]],
          category: '已支付订单环比',
          title: keys1[index]
        });
      });
      setDayOrders(as);
      return data;
    });
  };
  // 加载日销售额
  const loadDaySales = () => {
    axios.get(monitorUrls.daySales, { params: params }).then((data: any) => {
      const as: Array<INormalList> = [];
      const keys = getKeys(data?.zje);
      const keys1 = getKeys(data?.zje1);
      keys.forEach((item: string, index: number) => {
        if (item === 'total' || item === 'avg') {
          return;
        }
        const k = item.split('-')[2];
        // as.push({
        //   type: k,
        //   value: data?.zje[item],
        //   category: '总金额本周期',
        //   title: item
        // });
        // as.push({
        //   type: k,
        //   value: data?.zje1[keys1[index]],
        //   category: '总金额本环比',
        //   title: keys[index]
        // });
        // as.push({
        //   type: k,
        //   value: data?.wzfje[item],
        //   category: '未支付金额本周期',
        //   title: keys[index]
        // });
        // as.push({
        //   type: k,
        //   value: data?.wzfje1[keys1[index]],
        //   category: '未支付金额环比',
        //   title: keys[index]
        // });
        as.push({
          type: k,
          value: data?.yzfje[item],
          category: '已支付金额本周期',
          title: keys[index]
        });
        as.push({
          type: k,
          value: data?.yzfje1[keys1[index]],
          category: '已支付金额环比',
          title: keys1[index]
        });
      });
      setDaySales(as);
      return data;
    });
  };
  useEffect(() => {
    setTimeout(() => {
      loadActiveShopData();
      loadDayOrders();
      loadDaySales();
    });
  }, []);
  const config = {
    isGroup: true,
    data: activeShop,
    xField: 'type',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      max: getMax([...activeShop]),
      label: {
        // 数值格式化为千分位
        formatter: (v: any) => {
          return `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);
        }
      }
    },
    tooltip: {
      customContent: customContent
    },
    legend: { ...legend }
  };
  const configDay = {
    data: dayOrders,
    isGroup: true,
    xField: 'type',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      max: getMax([...dayOrders]),
      label: {
        // 数值格式化为千分位
        formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
      }
    },
    tooltip: {
      customContent: customContent
    },
    /** 设置颜色 */
    //color: ['#1ca9e6', '#f88c24'],

    /** 设置间距 */
    // marginRatio: 0.1,
    // label: {
    //   // 可手动配置 label 数据标签位置
    //   position: 'bottom' as const,
    //   // 'top', 'middle', 'bottom'
    //   // 可配置附加的布局方法
    //   layout: [
    //     // 柱形图数据标签位置自动调整
    //     {
    //       type: 'interval-adjust-position'
    //     }, // 数据标签防遮挡
    //     {
    //       type: 'interval-hide-overlap'
    //     }, // 数据标签文颜色自动调整
    //     {
    //       type: 'adjust-color'
    //     }
    //   ]
    // },
    legend: { ...legend }
  };
  const configSales = {
    data: daySales,
    xField: 'type',
    yField: 'value',
    seriesField: 'category',
    tooltip: {
      customContent: customContent
    },
    yAxis: {
      max: getMax([...daySales]),
      label: {
        // 数值格式化为千分位
        formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
      }
    },
    legend: { ...legend }
  };
  return (
    <div className="monitor-center">
      {/* 底部内容 */}
      <Row gutter={[16, 16]}>
        {/* 实时订单 */}
        <Col xs={24} lg={8}>
          <div className="chart-main">
            <p className="chart-title">
              <span>日活跃店铺数</span>
            </p>
            <div className="chart-content">
              <Area {...config} />
            </div>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="chart-main">
            <p className="chart-title">
              <span>日订单</span>
            </p>
            <div className="chart-content">
              <Column {...configDay} />
            </div>
          </div>
        </Col>
        <Col xs={24} lg={8}>
          <div className="chart-main">
            <p className="chart-title">
              <span>日销售额</span>
            </p>
            <div className="chart-content">
              <Line {...configSales} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BottomInfo;
