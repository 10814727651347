/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-24 09:34:08
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 09:38:56
 */
const shopTypes = {
  SET_TIME: 'SET_TIME'
};
export default shopTypes;
