export const timeOptions = [
  {
    key: '30m',
    value: 30 * 60,
    label: '30分钟'
  },
  {
    key: '1h',
    value: 1 * 60 * 60,
    label: '1小时'
  },
  {
    key: '3h',
    value: 3 * 60 * 60,
    label: '3小时'
  },
  {
    key: '6h',
    value: 6 * 60 * 60,
    label: '6小时'
  },
  {
    key: '12h',
    value: 12 * 60 * 60,
    label: '12小时'
  },
  {
    key: '24h',
    value: 24 * 60 * 60,
    label: '24小时'
  }
];
