/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-24 09:34:08
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 09:38:56
 */
import shopTypes from './shop';
import headerTypes from './header';
const actionTypes = {
  ...shopTypes,
  ...headerTypes
};
export default actionTypes;
