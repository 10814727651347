/*
 * @Author: xuyang
 * @Date: 2022-10-21 22:25:51
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-21 23:29:25
 */
import actionTypes from '../actionTypes';

const initState = {
  time: ''
};
export default function appReducer(state = initState, action: any) {
  switch (action.type) {
    case actionTypes.SET_TIME:
      return {
        ...state,
        time: action.params
      };
    default:
      return state;
  }
}
