/*
 * @Author: xuyang
 * @Date: 2022-10-18 00:47:54
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-31 20:21:03
 */
import Decimal from 'decimal.js';
// 图表显示的内容
export const customContent = (title: any, data: any) => {
  return `<div class='cs-main'>
                  <div class='cs-title'>环比</div>
                  <ul class='cs-list'>
                    ${data
                      .map(
                        (item: any) =>
                          `<li class="cs-item">
                            <span class="cs-marker" style="background: ${item.color}"></span>
                            <span class="cs-name">${item.data.title} ${item.name}</span>
                            <span class="cs-value">${
                              item.name.indexOf('转化率') >= 0
                                ? `${(parseFloat(item.value) * 100).toFixed(2)}%`
                                : item.value
                            }</span>
                          </li>`
                      )
                      .join('')}
                  </ul>
              </div>`;
};
// 配置图表图例
export const legend = {
  itemName: {
    style: {
      fill: '#fff'
    }
  },
  position: 'bottom' as const
};
export const getMax = (list: any) => {
  const it = list.sort((it1: any, it2: any) => it2.value - it1.value);
  console.log(it);
  if (it && it.length > 0) {
    const value = it[0].value;
    return Math.ceil(value * 1.5);
  }
  return 0;
};

export const formatZHL = (txt?: number) => {
  console.log(txt, 'decimal');
  if (txt !== null && txt !== undefined && !isNaN(txt)) {
    return Decimal.mul(txt, 100) + '%';
  }
  return '0%';
};
