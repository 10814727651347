import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { timeOptions } from '@/config/config';
const TimeCount = () => {
  const [value, setValue] = useState<number>(30 * 60);

  const onChange = (v: any) => {
    setValue(v);
    if (v) {
      localStorage.setItem('time', v);
    } else {
      localStorage.removeItem('time');
    }
    window.location.reload();
  };
  useEffect(() => {
    const to = localStorage.getItem('time');
    if (to) {
      setValue(parseInt(to));
    }
  }, []);
  return (
    <Select
      placeholder="时间"
      allowClear
      style={{ width: 160 }}
      options={timeOptions}
      value={value}
      onChange={onChange}
    ></Select>
  );
};

export default TimeCount;
