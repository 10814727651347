/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-24 09:28:21
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 10:53:17
 */
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from './middleware/Logger';
import rootReducer from './reducers';

export default createStore(rootReducer, applyMiddleware(thunk, logger));
