/*
 * @Author: xuyang
 * @Date: 2022-10-13 22:11:15
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-21 10:44:39
 */

import React, { useState, useEffect, FC } from 'react';
import { Row, Col } from 'antd';
import '../index.less';
import axios from '@/utils/request/request';
import { monitorUrls } from '../const';
import { IHeaderData } from '../type';
import userImg from '@/asset/img/users.png';
import shopImg from '@/asset/img/h-shop.png';
import shopImg1 from '@/asset/img/h-shop1.png';
import saleImg from '@/asset/img/h-order.png';
import zhuanImg from '@/asset/img/zhuan.png';
import orderImg from '@/asset/img/h-sale.png';
import { Link } from 'react-router-dom';
import { formatZHL, legend } from '@/utils';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
const TopInfo: FC = () => {
  const [activeShops, setActiveShops] = useState<IHeaderData>({
    zhydps: {},
    zzcdps: {},
    jrddl: {},
    jrxse: {},
    jrfks: {},
    zhlHbzz: 0,
    zrzhl: 0,
    jrzhl: 0
  });
  // 获取统计数据
  const loadSummary = () => {
    axios.get(monitorUrls.summary).then((data: any) => {
      setActiveShops(data);
      return data;
    });
  };

  useEffect(() => {
    loadSummary();
  }, []);
  // 头部配置
  const headerColConfig = {
    xs: {
      span: 24
    },
    lg: {
      span: 4
    },
    sm: {
      span: 12
    }
  };
  return (
    <div>
      {/* 头部信息 */}
      <Row gutter={[18, 18]}>
        {/* 周活跃店铺数 */}
        <Col {...headerColConfig}>
          <Link to="/trend/shop">
            <div className="header-item header-c1">
              <p className="title">周活跃店铺数</p>
              <p className="mainTxt">{activeShops.zhydps?.zhydps}</p>
              <div className="info">
                <div className="info-list">
                  <p>累计店铺数: {activeShops.zhydps?.ljdps}</p>
                  <p>
                    环比增长：{(activeShops.zhydps?.hbzz ? activeShops.zhydps?.hbzz * 100 : 0).toFixed(2) + '%'}
                    {activeShops.zhydps?.hbzz && activeShops.zhydps?.hbzz > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )}
                  </p>
                  <p>
                    上周活跃店铺数:{activeShops.zhydps?.szhydps}
                    {/* {activeShops.zhydps?.szhydps && activeShops.zhydps?.szhydps > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )} */}
                  </p>
                </div>
                <div className="icon">
                  <img src={shopImg} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </Col>
        {/* 周注册店铺数 */}
        <Col {...headerColConfig}>
          <Link to="/trend/shop">
            <div className="header-item header-c2">
              <p className="title">周注册店铺数</p>
              <p className="mainTxt">{activeShops.zzcdps?.zzcdps}</p>
              <div className="info">
                <div className="info-list">
                  <p>累计注册店铺: {activeShops.zzcdps?.ljzcdps}</p>
                  <p>
                    环比增长：{(activeShops.zzcdps?.hbzz ? activeShops.zzcdps?.hbzz * 100 : 0).toFixed(2) + '%'}
                    {activeShops.zzcdps?.hbzz && activeShops.zzcdps?.hbzz > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )}
                  </p>
                  <p>
                    上周注册店铺数: {activeShops.zzcdps?.szzcdps}
                    {/* {activeShops.zzcdps?.szzcdps && activeShops.zzcdps?.szzcdps > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )} */}
                  </p>
                </div>
                <div className="icon">
                  <img src={shopImg1} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </Col>
        {/* 今日订单量 */}
        <Col {...headerColConfig}>
          <Link to="/trend/order">
            <div className="header-item header-c3">
              <p className="title">今日订单量</p>
              <p className="mainTxt">{activeShops.jrddl?.jrddl}</p>
              <div className="info">
                <div className="info-list">
                  <p>周累计订单: {activeShops.jrddl?.bzljdd}</p>
                  <p>
                    环比增长：{(activeShops.jrddl?.hbzz ? activeShops.jrddl?.hbzz * 100 : 0).toFixed(2) + '%'}
                    {activeShops.jrddl?.hbzz && activeShops.jrddl?.hbzz > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )}
                  </p>
                  <p>
                    上周累计订单: {activeShops.jrddl?.szljdd}
                    {/* {activeShops.jrddl?.szljdd && activeShops.jrddl?.szljdd > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )} */}
                  </p>
                </div>
                <div className="icon">
                  <img src={orderImg} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </Col>
        {/* 今日销售额 */}
        <Col {...headerColConfig}>
          <Link to="/trend/sales">
            <div className="header-item header-c4">
              <p className="title">今日销售额</p>
              <p className="mainTxt">{activeShops.jrxse?.jrxse}</p>
              <div className="info">
                <div className="info-list">
                  <p>周累计销售额: {activeShops.jrxse?.bzljxse}</p>
                  <p>
                    环比增长：{(activeShops.jrxse?.hbzz ? activeShops.jrxse?.hbzz * 100 : 0).toFixed(2) + '%'}
                    {activeShops.jrxse?.hbzz && activeShops.jrxse?.hbzz > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )}
                  </p>
                  <p>
                    上周销售额: {activeShops.jrxse?.szljxse}
                    {/* {activeShops.jrxse?.szljxse && activeShops.jrxse?.szljxse > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )} */}
                  </p>
                </div>
                <div className="icon">
                  <img src={saleImg} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </Col>
        {/* 今日访客数 */}
        <Col {...headerColConfig}>
          <Link to="/trend/visiter">
            <div className="header-item header-c5">
              <p className="title">今日访客</p>
              <p className="mainTxt">{activeShops.jrfks?.jrfks}</p>
              <div className="info">
                <div className="info-list">
                  <p>周累计访客数: {activeShops.jrfks?.zljfks}</p>
                  <p>
                    环比增长：{(activeShops.jrfks?.hzzz ? activeShops.jrfks?.hzzz * 100 : 0).toFixed(2) + '%'}
                    {activeShops.jrfks?.hzzz && activeShops.jrfks?.hzzz > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )}
                  </p>
                  <p>
                    上周访客数:{activeShops.jrfks?.szljfks}
                    {/* {activeShops.jrfks?.szljfks && activeShops.jrfks?.szljfks > 0 ? (
                      <ArrowUpOutlined style={{ color: '#cf1322' }} />
                    ) : (
                      <ArrowDownOutlined style={{ color: '#3f8600' }} />
                    )} */}
                  </p>
                </div>
                <div className="icon">
                  <img src={userImg} alt="" />
                </div>
              </div>
            </div>
          </Link>
        </Col>
        {/* 访客数行为 */}
        <Col {...headerColConfig}>
          <div className="header-item header-c2">
            <p className="title">今日转化率</p>
            <p className="mainTxt"> {formatZHL(activeShops.jrzhl || 0)}</p>
            <div className="info">
              <div className="info-list">
                <p>昨日转化率: {formatZHL(activeShops.zrzhl || 0)}</p>
                <p>
                  环比增长：{(activeShops.zhlHbzz ? activeShops.zhlHbzz * 100 : 0).toFixed(2) + '%'}
                  {activeShops.zhlHbzz && activeShops.zhlHbzz > 0 ? (
                    <ArrowUpOutlined style={{ color: '#cf1322' }} />
                  ) : (
                    <ArrowDownOutlined style={{ color: '#3f8600' }} />
                  )}
                </p>
                <p>平均:{formatZHL(parseFloat(((activeShops.jrzhl + activeShops.zrzhl) / 2).toFixed(4)) || 0)}</p>
              </div>
              <div className="icon">
                <img src={zhuanImg} alt="" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default TopInfo;
