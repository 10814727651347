/*
 * @Author: xuyang
 * @Date: 2022-10-16 22:00:41
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-11-11 21:24:32
 * 店铺排行
 */
import React, { useState, useEffect, useCallback } from 'react';
import { Button, DatePicker, Table, Space, Spin, Switch, Input, Select } from 'antd';
import moment from 'moment';
import './index.less';
import axios from '@/utils/request/request';
import { shopStatus, shopUrls } from './const';
import { ISort } from './type';
import { useDispatch, useSelector } from 'react-redux';
import actionTypes from '@/store/actionTypes';
import { formatZHL } from '@/utils';
import { ReconciliationFilled } from '@ant-design/icons';
const ShopSetting = () => {
  const header = useSelector((state: any) => state.header);
  const dispatch = useDispatch();
  const shop = useSelector((state: any) => state.shop);
  const [key, setKeyValue] = useState<string>('');
  const [state, setStateValue] = useState<string>('');
  const [timeValue, setTimeValue] = useState<any>([moment().tz(header.timeZone), moment().tz(header.timeZone)]);
  const [tableData, setTableData] = useState<Array<any>>([]); // 表格数据
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<ISort>({
    columnKey: 'xse',
    field: 'xse',
    order: 'desc'
  });
  const getParams = () => ({
    key: key,
    state: state
  });

  let tabledata: any;
  // 加载数据
  const loadShopData = () => {
    setLoading(true);
    axios
      .get(shopUrls.rank, { params: getParams() })
      .then((data: any) => {
        tabledata = data;
        setTableData([...data]);
        setLoading(false);
        return data;
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 日期选择
  const timeChange = (value: any) => {
    setTimeValue(value);
  };
  // 搜索按钮
  const searchHandle = () => {
    loadShopData();
  };

  const onChange = (checked: boolean, record: any) => {
    //record.is_async = checked;
    setLoading(true);
    axios
      .get(checked ? shopUrls.startSync : shopUrls.stoptSync, { params: { id: record.id } })
      .then((data: any) => {
        setLoading(false);
      })
      .catch(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadShopData();
  }, [sort, setTimeValue]);
  const dispatchTitle = useCallback(
    (title: string) => dispatch({ type: actionTypes.SET_TITLE, params: title }),
    [dispatch]
  );
  useEffect(() => {
    dispatchTitle('店铺排行榜');
    if (shop.time.length > 0) {
      setTimeValue([moment(shop.time).tz(header.timeZone), moment(shop.time).tz(header.timeZone)]);
    }
  }, []);

  // 表格变化
  const tableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSort(sorter);
  };
  const columns: any = [
    {
      key: 'website',
      title: '店铺',
      dataIndex: 'website'
    },
    {
      key: 'status',
      title: '状态',
      align: 'right',
      dataIndex: 'status',
      render: (text: string) => {
        return shopStatus[text];
      }
    },
    {
      key: 'action',
      title: '是否统计',
      align: 'right',
      dataIndex: 'action',
      render(_text: string, record: any) {
        return (
          <div>
            <Switch defaultChecked={record.is_sync} onChange={(checked) => onChange(checked, record)} key={record.id} />
          </div>
        );
      }
    },
    {
      key: 'action',
      title: '查看',
      align: 'right',
      dataIndex: 'action',
      render(_text: string, record: any) {
        return (
          <Button type="link" target="_blank" href={record.url}>
            查看
          </Button>
        );
      }
    }
  ];
  return (
    <Spin spinning={loading}>
      <div className="shop-main">
        <div className="shop-search">
          <Space>
            <Input defaultValue={key} placeholder="输入关键字搜索" onChange={(e) => setKeyValue(e.target.value)} />
            <Select
              defaultValue="all"
              onChange={setStateValue}
              options={[
                { value: 'all', label: '所有' },
                { value: 'normal', label: '正常' },
                { value: 'hidden', label: '停用' }
              ]}
            />
            <Button type="primary" onClick={searchHandle}>
              确定
            </Button>
          </Space>
        </div>
        <div className="shop-table">
          <Table
            rowKey={(record: any) => record.shop_id + ''}
            columns={columns}
            dataSource={tableData}
            onChange={tableChange}
            scroll={{ x: 1200 }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default ShopSetting;
