/*
 * @Author: xuyang
 * @Date: 2022-09-28 22:30:37
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-28 23:56:16
 * @FilePath: \react_antd\src\views\LayoutPage\index.tsx
 * @Description: 整体布局页面
 */
import React, { useState } from 'react';
import { Button, Drawer, Layout, Space } from 'antd';
import Header from '@/components/HeaderComponent';
import './index.less';
import LeftMenu from '@/components/LeftMenu';
import { Routes, Route, useNavigate } from 'react-router-dom';
// import AllOrders from '../Orders/AllOrders';
// const AllOrders = React.lazy(() => import(/* webpackChunkName:'AllOrders' */ '@/views/Orders/AllOrders'));
import AllOrders from '../Orders/AllOrders';
import Monitor from '../Monitor';
import Trend from '../Trend';
import ShopRank from '../ShopRank';
import ProductRank from '../ProductRank';
import ShopSetting from '../ShopSetting';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { LoginOutlined } from '@ant-design/icons';
import { remove } from 'react-cookies';

const { Sider, Content } = Layout;

function LayoutPage(props: any) {
  moment.locale('zh-cn');
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false); // 显示移动端菜单
  // 菜单栏展开
  const onMenuCollapsed = (flag: boolean) => {
    setCollapsed(flag);
  };
  // 显示移动端菜单
  const onMenuChange = (flag: boolean) => {
    setShowMenu(flag);
  };
  // 退出登录
  const logout = () => {
    remove('token');
    navigate('login');
  };
  return (
    <Layout className="app-main">
      {/* 底部 */}
      <Layout>
        {/* 左侧菜单 pc 端显示 mobile 显示 Drawer */}
        {!isMobile ? (
          <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
            <LeftMenu collapsed={collapsed}></LeftMenu>
          </Sider>
        ) : (
          <Drawer
            className="app-mobile-menu"
            title={<div className="mobile-title">shoppaas</div>}
            onClose={() => onMenuChange(false)}
            placement="left"
            width="80%"
            open={showMenu}
            headerStyle={{ backgroundColor: '#03375f' }}
            bodyStyle={{ padding: 0 }}
            extra={<Space></Space>}
          >
            <div className="mobile-menu">
              <LeftMenu onChange={onMenuChange}></LeftMenu>
              <Button className="btn-logout" icon={<LoginOutlined />} onClick={logout}>
                退出登录
              </Button>
            </div>
          </Drawer>
        )}
        {/* 内容 */}
        <Content className="content-main">
          {/* 头部 */}
          <Header onSetCollapsed={onMenuCollapsed} onShowMenu={onMenuChange}></Header>
          <Routes>
            <Route path="/monitor" element={<Monitor />}></Route>
            <Route path="/allOrders" element={<AllOrders />}></Route>
            <Route path="/shopRank" element={<ShopRank />}></Route>
            <Route path="/productRank" element={<ProductRank />}></Route>
            <Route path="/trend/:type" element={<Trend />}></Route>
            <Route path="/shopSetting" element={<ShopSetting />}></Route>
            {/* 重定向 */}
            <Route path="*" element={<Monitor />}></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
}

export default LayoutPage;
