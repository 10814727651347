import React, { FC, useEffect, useState } from 'react';
import { Pie } from '@ant-design/charts';
import { Row, Col } from 'antd';
import axios from '@/utils/request/request';
import { monitorUrls } from '../const';
import { ICenterData } from '../type';
import bag2 from '@/asset/img/bag2.png';
import bag1 from '@/asset/img/bag1.png';
import money1 from '@/asset/img/money1.png';
import money2 from '@/asset/img/money2.png';
import zhl from '@/asset/img/zhl.png';
import { useLocation } from 'react-router-dom';
import { formatZHL, legend } from '@/utils';
import { timeOptions } from '@/config/config';
import _ from 'lodash';
const CenterInfo: FC = () => {
  const [timeCount, setTimeCount] = useState<number>(30 * 60);
  const [timeTxt, setTimeTxt] = useState<string>('30分钟');
  const [centerData, setCenterData] = useState<ICenterData>({
    ssdd: {},
    ssxssj: {},
    ssfkxw: {}
  });
  const [inter, setInter] = useState<any>(null);
  const location = useLocation();
  // 加载数据
  const loadData = (time?: number) => {
    axios.get(monitorUrls.summaryCenter, { params: { time: time || timeCount } }).then((data: any) => {
      setCenterData(data);
      return data;
    });
  };
  const initData = (time: number) => {
    loadData(time);
    const interObj = setInterval(() => {
      if (location.pathname === '/' || location.pathname === '/monitor') {
        //每分钟轮一次
        loadData();
      }
    }, 1000 * 60);
    setInter(interObj);
  };
  // useEffect(() => {
  //   loadData();
  //   const interObj = setInterval(() => {
  //     if (location.pathname === '/' || location.pathname === '/monitor') {
  //       //每分钟轮一次
  //       loadData();
  //     }
  //   }, 1000 * 60);
  //   setInter(interObj);
  // }, [timeCount]);
  useEffect(() => {
    return () => {
      // 销毁
      if (inter) {
        clearInterval(inter);
        setInter(null);
      }
    };
  }, []);

  useEffect(() => {
    const t = localStorage.getItem('time');
    if (t) {
      setTimeCount(parseInt(t));
      initData(parseInt(t));
    } else {
      initData(timeCount);
    }
  }, []);
  useEffect(() => {
    const to = _.find(timeOptions, { value: timeCount });
    if (to) {
      setTimeTxt(to.label);
    }
  }, [timeCount]);
  // 环形配置
  const orderConfig = {
    appendPadding: 10,
    data: [
      {
        type: '已支付',
        value: centerData.ssdd?.yzfdd
      },
      {
        type: '未支付',
        value: centerData.ssdd?.wzfdd
      }
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    statistic: {
      style: {
        fontSize: 80,
        fontWeight: 300,
        textAlign: 'center',
        textBaseline: 'middle',
        shadowColor: 'white',
        shadowBlur: 10
      },
      title: false,
      content: {
        content: ''
      }
    },
    legend: { ...legend }
  };

  const saleConfig = {
    appendPadding: 10,
    data: [
      {
        type: '已支付金额',
        value: centerData.ssxssj?.yzfje
      },
      {
        type: '未支付金额',
        value: centerData.ssxssj?.wzfje
      }
    ],
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center'
      }
    },
    interactions: [
      {
        type: 'element-active'
      }
    ],
    legend: { ...legend }
  };
  return (
    <div className="monitor-center">
      {/* 中间部分内容 */}
      <Row gutter={[16, 16]}>
        {/* 实时订单 */}
        <Col xs={24} lg={9}>
          <div className="chart-main">
            <p className="chart-title">
              <span>
                实时订单<span className="mini-tit">(过去{timeTxt})</span>
              </span>
            </p>
            <div className="chart-content">
              <Pie {...orderConfig}></Pie>
            </div>
          </div>
        </Col>
        {/* 实时销售额 */}
        <Col xs={24} lg={6}>
          <div className="chart-main">
            <p className="chart-title">
              <span>
                实时销售数据<span className="mini-tit">(过去{timeTxt})</span>
              </span>
            </p>
            <div className="chart-content chart-content1">
              <Pie {...saleConfig}></Pie>
            </div>
          </div>
        </Col>
        <Col xs={24} lg={9}>
          <div className="chart-main">
            <p className="chart-title">
              <span>
                实时访客行为<span className="mini-tit">(过去{timeTxt})</span>
              </span>
            </p>
            <div className="visitor">
              <div className="visitor-item">
                <div className="icon">
                  <img src={bag1} alt="" />
                </div>
                <div className="visitor-info">
                  <p>{centerData.ssfkxw?.fks}</p>
                  <p>访客数</p>
                </div>
              </div>
              <div className="visitor-item">
                <div className="icon">
                  <img src={bag2} alt="" />
                </div>
                <div className="visitor-info">
                  <p>{centerData.ssfkxw?.jrjz}</p>
                  <p>加入购物车</p>
                </div>
              </div>
              <div className="visitor-item">
                <div className="icon">
                  <img src={money1} alt="" />
                </div>
                <div className="visitor-info">
                  <p>{centerData.ssfkxw?.jrjz}</p>
                  <p>进入结账</p>
                </div>
              </div>
              <div className="visitor-item">
                <div className="icon">
                  <img src={money2} alt="" />
                </div>
                <div className="visitor-info">
                  <p>{centerData.ssfkxw?.wczf}</p>
                  <p>完成支付</p>
                </div>
              </div>
              <div className="visitor-item">
                <div className="icon">
                  <img src={zhl} alt="" />
                </div>
                <div className="visitor-info">
                  <p>{formatZHL(centerData.ssfkxw?.zhl || 0)}</p>
                  <p>转化率</p>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CenterInfo;
