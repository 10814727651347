import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import axios from '@/utils/request/request';
import { headerUrls } from './const';
const Paytype = () => {
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<string | null>('all');
  const fetchArea = () => {
    axios
      .get(headerUrls.paytype)
      .then((data: any) => {
        const area = data.map((item: any) => ({
          label: item,
          value: item,
          key: item
        }));
        setOptions([{ label: '全选', value: 'all', key: 'all' }, ...area]);
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const onChange = (v: any) => {
    if (v && v !== 'all') {
      localStorage.setItem('pay', v);
      setValue(v);
    } else {
      localStorage.removeItem('pay');
      setValue('all');
    }
    window.location.reload();
  };
  useEffect(() => {
    const pay = localStorage.getItem('pay');
    if (pay) {
      setValue(pay);
    }
  }, [options]);
  useEffect(() => {
    fetchArea();
  }, []);
  return (
    <Select
      placeholder="支付方式"
      allowClear
      style={{ width: 160 }}
      options={options}
      value={value}
      onChange={onChange}
    ></Select>
  );
};

export default Paytype;
