import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setActionData } from '@/actions';
import actionTypes from '@/store/actionTypes';
import { timeData } from './config';
import { isMobile } from 'react-device-detect';
const Option = Select.Option;
interface ITimeZone {
  value?: string;
  onChange?: (value: string) => void;
  className?: string;
  styles?: any;
}
function TImeZone({ value, onChange, className, styles }: ITimeZone) {
  const header = useSelector((state: any) => state.header);
  const dispatch = useDispatch();
  // 筛选
  const selectChange = (timeValue: string) => {
    // 设置到reducer
    dispatch(setActionData(actionTypes.SET_TIME_ZONE, timeValue));
    localStorage.setItem('sq', timeValue);
    location.reload();
    return;
    // if (onChange) {
    //    onChange(timeValue);
    // }
  };
  useEffect(() => {
    // const sq = cookie.load('sq');
    const sq = localStorage.getItem('sq') ?? 'Asia/Shanghai';
    if (!sq && !header.timeZone) {
      localStorage.setItem('sq', header.timeZone);
    } else {
      dispatch(setActionData(actionTypes.SET_TIME_ZONE, sq));
    }
  }, []);
  return (
    <Select
      filterOption={(input, option) =>
        (option!.search as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
      showSearch
      className={className}
      style={styles}
      value={value || header.timeZone}
      onChange={selectChange}
    >
      {timeData.map((item) => (
        <Option key={item.key} value={item.value} search={item.title}>
          {isMobile ? item.miniTitle : item.title}
        </Option>
      ))}
    </Select>
  );
}

export default TImeZone;
