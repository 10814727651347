/*
 * @Author: xuyang
 * @Date: 2022-10-12 23:52:30
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-21 10:58:30
 * 数据监控
 */
import React, { FC, useCallback, useEffect } from 'react';
import './index.less';
import TopInfo from './components/TopInfo';
import CenterInfo from './components/CenterInfo';
import BottomInfo from './components/BottomInfo';
import actionTypes from '@/store/actionTypes';
import { useDispatch } from 'react-redux';
import BtmInfo from './components/BtmInfo';

const Monitor: FC = () => {
  const dispatch = useDispatch();
  const dispatchTitle = useCallback(
    (title: string) => dispatch({ type: actionTypes.SET_TITLE, params: title }),
    [dispatch]
  );
  useEffect(() => {
    dispatchTitle('数据概览');
  }, []);
  return (
    <div className="monitor">
      {/* 头部信息 */}
      <TopInfo />
      {/* 中间信息 */}
      <CenterInfo />
      <BottomInfo />
      {/* <BtmInfo /> */}
    </div>
  );
};
export default Monitor;
