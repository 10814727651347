/*
 * @Author: xuyang
 * @Date: 2022-10-21 22:25:51
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-26 00:17:56
 */
import actionTypes from '../actionTypes';

const initState = {
  title: '数据概览',
  timeZone: 'Asia/Shanghai' // 时区 默认北京
};
export default function appReducer(state = initState, action: any) {
  switch (action.type) {
    case actionTypes.SET_TITLE:
      return {
        ...state,
        title: action.params
      };
    case actionTypes.SET_TIME_ZONE:
      return {
        ...state,
        timeZone: action.payload
      };
    default:
      return state;
  }
}
