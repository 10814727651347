/*
 * @Author: xuyang
 * @Date: 2022-10-14 23:57:49
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-15 15:00:59
 */
export const monitorUrls = {
  summary: '/summary', // 统计数据
  summaryCenter: '/summary/sssj', // 统计中间数据
  activeShop: '/shop/hy', // 日活跃店铺数
  dayOrders: '/order/sl', // 日订单数
  daySales: 'order/je', // 日销售额
  country: '/order/gj', //国家统计
  paytype: '/order/paytype' // 支付方式统计
};
