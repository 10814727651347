export const timeData: any[] = [
  /*{
    key: 'Etc/GMT+12',
    value: 'Etc/GMT+12',
    title: '(GMT-12:00) 国际日期变更线西边',
    miniTitle: '(GMT-12:00)'
  },
  {
    key: 'Pacific/Pago_Pago',
    value: 'Pacific/Pago_Pago',
    title: '(GMT-11:00) 美属萨摩亚',
    miniTitle: '(GMT-11:00)'
  },
  {
    key: 'Pacific/Midway',
    value: 'Pacific/Midway',
    title: '(GMT-11:00) 中途岛',
    miniTitle: '(GMT-11:00)'
  },
  {
    key: 'Pacific/Honolulu',
    value: 'Pacific/Honolulu',
    title: '(GMT-10:00) 夏威夷',
    miniTitle: '(GMT-10:00)'
  },
  {
    key: 'America/Juneau',
    value: 'America/Juneau',
    title: '(GMT-09:00) 阿拉斯加',
    miniTitle: '(GMT-09:00)'
  },
  {
    key: 'America/Los_Angeles',
    value: 'America/Los_Angeles',
    title: '(GMT-08:00) 太平洋时间（美国和加拿大）',
    miniTitle: '(GMT-08:00)'
  },
  {
    key: 'America/Tijuana',
    value: 'America/Tijuana',
    title: '(GMT-08:00) 蒂华纳',
    miniTitle: '(GMT-08:00)'
  },
  {
    key: 'America/Phoenix',
    value: 'America/Phoenix',
    title: '(GMT-07:00) 亚利桑那',
    miniTitle: '(GMT-07:00)'
  },
  {
    key: 'America/Chihuahua',
    value: 'America/Chihuahua',
    title: '(GMT-07:00) 奇瓦瓦',
    miniTitle: '(GMT-07:00)'
  },
  {
    key: 'America/Mazatlan',
    value: 'America/Mazatlan',
    title: '(GMT-07:00) 马萨特兰',
    miniTitle: '(GMT-07:00)'
  },
  {
    key: 'America/Denver',
    value: 'America/Denver',
    title: '(GMT-07:00) 山区时间（美国和加拿大）',
    miniTitle: '(GMT-07:00)'
  },
  {
    key: 'America/Guatemala',
    value: 'America/Guatemala',
    title: '(GMT-06:00) 中美洲',
    miniTitle: '(GMT-06:00)'
  },
  {
    key: 'America/Chicago',
    value: 'America/Chicago',
    title: '(GMT-06:00) 中部时间（美国和加拿大）',
    miniTitle: '(GMT-06:00)'
  },
  {
    key: 'America/Mexico_City',
    value: 'America/Mexico_City',
    title: '(GMT-06:00) 瓜达拉哈拉、墨西哥城',
    miniTitle: '(GMT-06:00)'
  },
  {
    key: 'America/Monterrey',
    value: 'America/Monterrey',
    title: '(GMT-06:00) 蒙特雷',
    miniTitle: '(GMT-06:00)'
  },
  {
    key: 'America/Regina',
    value: 'America/Regina',
    title: '(GMT-06:00) 萨斯喀彻温',
    miniTitle: '(GMT-06:00)'
  },
  {
    key: 'America/Bogota',
    value: 'America/Bogota',
    title: '(GMT-05:00) 波哥大',
    miniTitle: '(GMT-05:00)'
  },
  */
  {
    key: 'America/New_York',
    value: 'America/New_York',
    title: '(GMT-05:00) 东部时间（美国和加拿大）',
    miniTitle: '(GMT-05:00)'
  },
  /*
  {
    key: 'America/Indiana/Indianapolis',
    value: 'America/Indiana/Indianapolis',
    title: '(GMT-05:00) 印第安纳（东部）',
    miniTitle: '(GMT-05:00)'
  },
  {
    key: 'America/Lima',
    value: 'America/Lima',
    title: '(GMT-05:00) 利马、基多',
    miniTitle: '(GMT-05:00)'
  },
  {
    key: 'America/Halifax',
    value: 'America/Halifax',
    title: '(GMT-04:00) 大西洋时间（加拿大）',
    miniTitle: '(GMT-04:00)'
  },
  {
    key: 'America/Caracas',
    value: 'America/Caracas',
    title: '(GMT-04:00) 加拉加斯',
    miniTitle: '(GMT-04:00)'
  },
  {
    key: 'America/Guyana',
    value: 'America/Guyana',
    title: '(GMT-04:00) 乔治敦',
    miniTitle: '(GMT-04:00)'
  },
  {
    key: 'America/La_Paz',
    value: 'America/La_Paz',
    title: '(GMT-04:00) 拉巴斯',
    miniTitle: '(GMT-04:00)'
  },
  {
    key: 'America/Puerto_Rico',
    value: 'America/Puerto_Rico',
    title: '(GMT-04:00) 波多黎各',
    miniTitle: '(GMT-04:00)'
  },
  {
    key: 'America/Santiago',
    value: 'America/Santiago',
    title: '(GMT-04:00) 圣地亚哥',
    miniTitle: '(GMT-04:00)'
  },
  {
    key: 'America/St_Johns',
    value: 'America/St_Johns',
    title: '(GMT-03:30) 纽芬兰',
    miniTitle: '(GMT-03:30)'
  },
  {
    key: 'America/Sao_Paulo',
    value: 'America/Sao_Paulo',
    title: '(GMT-03:00) 巴西利亚',
    miniTitle: '(GMT-03:00)'
  },
  {
    key: 'America/Argentina/Buenos_Aires',
    value: 'America/Argentina/Buenos_Aires',
    title: '(GMT-03:00) 布宜诺斯艾利斯',
    miniTitle: '(GMT-03:00)'
  },
  {
    key: 'America/Godthab',
    value: 'America/Godthab',
    title: '(GMT-03:00) 格陵兰岛',
    miniTitle: '(GMT-03:00)'
  },
  {
    key: 'America/Montevideo',
    value: 'America/Montevideo',
    title: '(GMT-03:00) 蒙得维的亚',
    miniTitle: '(GMT-03:00)'
  },
  {
    key: 'Atlantic/South_Georgia',
    value: 'Atlantic/South_Georgia',
    title: '(GMT-02:00) 大西洋中部',
    miniTitle: '(GMT-02:00)'
  },
  {
    key: 'Atlantic/Azores',
    value: 'Atlantic/Azores',
    title: '(GMT-01:00) 亚速尔群岛',
    miniTitle: '(GMT-01:00)'
  },
  {
    key: 'Atlantic/Cape_Verde',
    value: 'Atlantic/Cape_Verde',
    title: '(GMT-01:00) 佛得角群岛',
    miniTitle: '(GMT-01:00)'
  },
  {
    key: 'Europe/London',
    value: 'Europe/London',
    title: '(GMT+00:00) 爱丁堡、伦敦',
    miniTitle: '(GMT+00:00)'
  },
  {
    key: 'Europe/Lisbon',
    value: 'Europe/Lisbon',
    title: '(GMT+00:00) 里斯本',
    miniTitle: '(GMT+00:00)'
  },
  {
    key: 'Africa/Monrovia',
    value: 'Africa/Monrovia',
    title: '(GMT+00:00) 蒙罗维亚',
    miniTitle: '(GMT+00:00)'
  },
  {
    key: 'Etc/UTC',
    value: 'Etc/UTC',
    title: '(GMT+00:00) UTC',
    miniTitle: '(GMT+00:00)'
  },
  {
    key: 'Europe/Amsterdam',
    value: 'Europe/Amsterdam',
    title: '(GMT+01:00) 阿姆斯特丹',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Belgrade',
    value: 'Europe/Belgrade',
    title: '(GMT+01:00) 贝尔格莱德',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Berlin',
    value: 'Europe/Berlin',
    title: '(GMT+01:00) 柏林',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Zurich',
    value: 'Europe/Zurich',
    title: '(GMT+01:00) 伯尔尼、苏黎世',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Bratislava',
    value: 'Europe/Bratislava',
    title: '(GMT+01:00) 布拉迪斯拉发',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Brussels',
    value: 'Europe/Brussels',
    title: '(GMT+01:00) 布鲁塞尔',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Budapest',
    value: 'Europe/Budapest',
    title: '(GMT+01:00) 布达佩斯',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Africa/Casablanca',
    value: 'Africa/Casablanca',
    title: '(GMT+01:00) 卡萨布兰卡',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Copenhagen',
    value: 'Europe/Copenhagen',
    title: '(GMT+01:00) 哥本哈根',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Dublin',
    value: 'Europe/Dublin',
    title: '(GMT+00:00) 都柏林',
    miniTitle: '(GMT+00:00)'
  },
  {
    key: 'Europe/Ljubljana',
    value: 'Europe/Ljubljana',
    title: '(GMT+01:00) 卢布尔雅那',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Madrid',
    value: 'Europe/Madrid',
    title: '(GMT+01:00) 马德里',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Paris',
    value: 'Europe/Paris',
    title: '(GMT+01:00) 巴黎',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Prague',
    value: 'Europe/Prague',
    title: '(GMT+01:00) 布拉格',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Rome',
    value: 'Europe/Rome',
    title: '(GMT+01:00) 罗马',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Sarajevo',
    value: 'Europe/Sarajevo',
    title: '(GMT+01:00) 萨拉热窝',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Skopje',
    value: 'Europe/Skopje',
    title: '(GMT+01:00) 斯科普里',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Stockholm',
    value: 'Europe/Stockholm',
    title: '(GMT+01:00) 斯德哥尔摩',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Vienna',
    value: 'Europe/Vienna',
    title: '(GMT+01:00) 维也纳',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Warsaw',
    value: 'Europe/Warsaw',
    title: '(GMT+01:00) 华沙',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Africa/Algiers',
    value: 'Africa/Algiers',
    title: '(GMT+01:00) 中非西部',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Zagreb',
    value: 'Europe/Zagreb',
    title: '(GMT+01:00) 萨格勒布',
    miniTitle: '(GMT+01:00)'
  },
  {
    key: 'Europe/Athens',
    value: 'Europe/Athens',
    title: '(GMT+02:00) 雅典',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Bucharest',
    value: 'Europe/Bucharest',
    title: '(GMT+02:00) 布加勒斯特',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Africa/Cairo',
    value: 'Africa/Cairo',
    title: '(GMT+02:00) 开罗',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Africa/Harare',
    value: 'Africa/Harare',
    title: '(GMT+02:00) 哈拉雷',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Helsinki',
    value: 'Europe/Helsinki',
    title: '(GMT+02:00) 赫尔辛基',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Asia/Jerusalem',
    value: 'Asia/Jerusalem',
    title: '(GMT+02:00) 耶路撒冷',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Kaliningrad',
    value: 'Europe/Kaliningrad',
    title: '(GMT+02:00) 加里宁格勒',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Kiev',
    value: 'Europe/Kiev',
    title: '(GMT+02:00) 基辅',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Africa/Johannesburg',
    value: 'Africa/Johannesburg',
    title: '(GMT+02:00) 比勒陀利亚',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Riga',
    value: 'Europe/Riga',
    title: '(GMT+02:00) 里加',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Sofia',
    value: 'Europe/Sofia',
    title: '(GMT+02:00) 索菲亚',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Tallinn',
    value: 'Europe/Tallinn',
    title: '(GMT+02:00) 塔林',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Europe/Vilnius',
    value: 'Europe/Vilnius',
    title: '(GMT+02:00) 维尔纽斯',
    miniTitle: '(GMT+02:00)'
  },
  {
    key: 'Asia/Baghdad',
    value: 'Asia/Baghdad',
    title: '(GMT+03:00) 巴格达',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Europe/Istanbul',
    value: 'Europe/Istanbul',
    title: '(GMT+03:00) 伊斯坦布尔',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Asia/Kuwait',
    value: 'Asia/Kuwait',
    title: '(GMT+03:00) 科威特',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Europe/Minsk',
    value: 'Europe/Minsk',
    title: '(GMT+03:00) 明斯克',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Europe/Moscow',
    value: 'Europe/Moscow',
    title: '(GMT+03:00) 莫斯科、圣彼得堡',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Africa/Nairobi',
    value: 'Africa/Nairobi',
    title: '(GMT+03:00) 内罗毕',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Asia/Riyadh',
    value: 'Asia/Riyadh',
    title: '(GMT+03:00) 利雅得',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Europe/Volgograd',
    value: 'Europe/Volgograd',
    title: '(GMT+03:00) 伏尔加格勒',
    miniTitle: '(GMT+03:00)'
  },
  {
    key: 'Asia/Tehran',
    value: 'Asia/Tehran',
    title: '(GMT+03:30) 德黑兰',
    miniTitle: '(GMT+03:30)'
  },
  {
    key: 'Asia/Muscat',
    value: 'Asia/Muscat',
    title: '(GMT+04:00) 阿布扎比、马斯喀特',
    miniTitle: '(GMT+04:00)'
  },
  {
    key: 'Asia/Baku',
    value: 'Asia/Baku',
    title: '(GMT+04:00) 巴库',
    miniTitle: '(GMT+04:00)'
  },
  {
    key: 'Europe/Samara',
    value: 'Europe/Samara',
    title: '(GMT+04:00) 萨马拉',
    miniTitle: '(GMT+04:00)'
  },
  {
    key: 'Asia/Tbilisi',
    value: 'Asia/Tbilisi',
    title: '(GMT+04:00) 第比利斯',
    miniTitle: '(GMT+04:00)'
  },
  {
    key: 'Asia/Yerevan',
    value: 'Asia/Yerevan',
    title: '(GMT+04:00) 埃里温',
    miniTitle: '(GMT+04:00)'
  },
  {
    key: 'Asia/Kabul',
    value: 'Asia/Kabul',
    title: '(GMT+04:30) 喀布尔',
    miniTitle: '(GMT+04:30)'
  },
  {
    key: 'Asia/Yekaterinburg',
    value: 'Asia/Yekaterinburg',
    title: '(GMT+05:00) 叶卡捷林堡',
    miniTitle: '(GMT+05:00)'
  },
  {
    key: 'Asia/Karachi',
    value: 'Asia/Karachi',
    title: '(GMT+05:00) 伊斯兰堡、卡拉奇',
    miniTitle: '(GMT+05:00)'
  },
  {
    key: 'Asia/Tashkent',
    value: 'Asia/Tashkent',
    title: '(GMT+05:00) 塔什干',
    miniTitle: '(GMT+05:00)'
  },
  {
    key: 'Asia/Kolkata',
    value: 'Asia/Kolkata',
    title: '(GMT+05:30) 金奈、加尔各答、孟买、新德里',
    miniTitle: '(GMT+05:30)'
  },
  {
    key: 'Asia/Colombo',
    value: 'Asia/Colombo',
    title: '(GMT+05:30) 斯里哈亚华登尼普拉',
    miniTitle: '(GMT+05:30)'
  },
  {
    key: 'Asia/Kathmandu',
    value: 'Asia/Kathmandu',
    title: '(GMT+05:45) 加德满都',
    miniTitle: '(GMT+05:45)'
  },
  {
    key: 'Asia/Almaty',
    value: 'Asia/Almaty',
    title: '(GMT+06:00) 阿拉木图',
    miniTitle: '(GMT+06:00)'
  },
  {
    key: 'Asia/Dhaka',
    value: 'Asia/Dhaka',
    title: '(GMT+06:00) 阿斯塔纳、达卡',
    miniTitle: '(GMT+06:00)'
  },
  {
    key: 'Asia/Urumqi',
    value: 'Asia/Urumqi',
    title: '(GMT+06:00) 乌鲁木齐',
    miniTitle: '(GMT+06:00)'
  },
  {
    key: 'Asia/Rangoon',
    value: 'Asia/Rangoon',
    title: '(GMT+06:30) 仰光',
    miniTitle: '(GMT+06:30)'
  },
  {
    key: 'Asia/Bangkok',
    value: 'Asia/Bangkok',
    title: '(GMT+07:00) 曼谷、河内',
    miniTitle: '(GMT+07:00)'
  },
  {
    key: 'Asia/Jakarta',
    value: 'Asia/Jakarta',
    title: '(GMT+07:00) 雅加达',
    miniTitle: '(GMT+07:00)'
  },
  {
    key: 'Asia/Krasnoyarsk',
    value: 'Asia/Krasnoyarsk',
    title: '(GMT+07:00) 克拉斯诺亚尔斯克',
    miniTitle: '(GMT+07:00)'
  },
  {
    key: 'Asia/Novosibirsk',
    value: 'Asia/Novosibirsk',
    title: '(GMT+07:00) 新西伯利亚',
    miniTitle: '(GMT+07:00)'
  },
  */
  {
    key: 'Asia/Shanghai',
    value: 'Asia/Shanghai',
    title: '(GMT+08:00) 北京',
    miniTitle: '(GMT+08:00)'
  }
  /*
  {
    key: 'Asia/Chongqing',
    value: 'Asia/Chongqing',
    title: '(GMT+08:00) 重庆',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Hong_Kong',
    value: 'Asia/Hong_Kong',
    title: '(GMT+08:00) 香港',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Irkutsk',
    value: 'Asia/Irkutsk',
    title: '(GMT+08:00) 伊尔库次克',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Kuala_Lumpur',
    value: 'Asia/Kuala_Lumpur',
    title: '(GMT+08:00) 吉隆坡',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Australia/Perth',
    value: 'Australia/Perth',
    title: '(GMT+08:00) 珀斯',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Singapore',
    value: 'Asia/Singapore',
    title: '(GMT+08:00) 新加坡',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Taipei',
    value: 'Asia/Taipei',
    title: '(GMT+08:00) 台北',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Ulaanbaatar',
    value: 'Asia/Ulaanbaatar',
    title: '(GMT+08:00) 乌兰巴托',
    miniTitle: '(GMT+08:00)'
  },
  {
    key: 'Asia/Tokyo',
    value: 'Asia/Tokyo',
    title: '(GMT+09:00) 大阪、札幌、东京',
    miniTitle: '(GMT+09:00)'
  },
  {
    key: 'Asia/Seoul',
    value: 'Asia/Seoul',
    title: '(GMT+09:00) 首尔',
    miniTitle: '(GMT+09:00)'
  },
  {
    key: 'Asia/Yakutsk',
    value: 'Asia/Yakutsk',
    title: '(GMT+09:00) 雅库茨克',
    miniTitle: '(GMT+09:00)'
  },
  {
    key: 'Australia/Adelaide',
    value: 'Australia/Adelaide',
    title: '(GMT+09:30) 阿德莱德',
    miniTitle: '(GMT+09:30)'
  },
  {
    key: 'Australia/Darwin',
    value: 'Australia/Darwin',
    title: '(GMT+09:30) 达尔文',
    miniTitle: '(GMT+09:30)'
  },
  {
    key: 'Australia/Brisbane',
    value: 'Australia/Brisbane',
    title: '(GMT+10:00) 布里斯班',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Australia/Melbourne',
    value: 'Australia/Melbourne',
    title: '(GMT+10:00) 堪培拉、墨尔本',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Pacific/Guam',
    value: 'Pacific/Guam',
    title: '(GMT+10:00) 关岛',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Australia/Hobart',
    value: 'Australia/Hobart',
    title: '(GMT+10:00) 霍巴特',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Pacific/Port_Moresby',
    value: 'Pacific/Port_Moresby',
    title: '(GMT+10:00) 莫尔兹比港',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Australia/Sydney',
    value: 'Australia/Sydney',
    title: '(GMT+10:00) 悉尼',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Asia/Vladivostok',
    value: 'Asia/Vladivostok',
    title: '(GMT+10:00) 符拉迪沃斯托克',
    miniTitle: '(GMT+10:00)'
  },
  {
    key: 'Asia/Magadan',
    value: 'Asia/Magadan',
    title: '(GMT+11:00) 马加丹',
    miniTitle: '(GMT+11:00)'
  },
  {
    key: 'Pacific/Noumea',
    value: 'Pacific/Noumea',
    title: '(GMT+11:00) 新喀里多尼亚',
    miniTitle: '(GMT+11:00)'
  },
  {
    key: 'Pacific/Guadalcanal',
    value: 'Pacific/Guadalcanal',
    title: '(GMT+11:00) 所罗门群岛',
    miniTitle: '(GMT+11:00)'
  },
  {
    key: 'Asia/Srednekolymsk',
    value: 'Asia/Srednekolymsk',
    title: '(GMT+11:00) 中科雷姆斯克',
    miniTitle: '(GMT+11:00)'
  },
  {
    key: 'Pacific/Auckland',
    value: 'Pacific/Auckland',
    title: '(GMT+12:00) 奥克兰、惠灵顿',
    miniTitle: '(GMT+12:00)'
  },
  {
    key: 'Pacific/Fiji',
    value: 'Pacific/Fiji',
    title: '(GMT+12:00) 斐济',
    miniTitle: '(GMT+12:00)'
  },
  {
    key: 'Asia/Kamchatka',
    value: 'Asia/Kamchatka',
    title: '(GMT+12:00) 堪察加',
    miniTitle: '(GMT+12:00)'
  },
  {
    key: 'Pacific/Majuro',
    value: 'Pacific/Majuro',
    title: '(GMT+12:00) 马绍尔群岛',
    miniTitle: '(GMT+12:00)'
  },
  {
    key: 'Pacific/Chatham',
    value: 'Pacific/Chatham',
    title: '(GMT+12:45) 查塔姆群岛',
    miniTitle: '(GMT+12:45)'
  },
  {
    key: 'Pacific/Tongatapu',
    value: 'Pacific/Tongatapu',
    title: '(GMT+13:00) 努库阿洛法',
    miniTitle: '(GMT+13:00)'
  },
  {
    key: 'Pacific/Apia',
    value: 'Pacific/Apia',
    title: '(GMT+13:00) 萨摩亚',
    miniTitle: '(GMT+13:00)'
  },
  {
    key: 'Pacific/Fakaofo',
    value: 'Pacific/Fakaofo',
    title: '(GMT+13:00) 托克劳群岛',
    miniTitle: '(GMT+13:00)'
  }*/
];
