/* eslint-disable @typescript-eslint/ban-ts-comment */
/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-22 23:35:06
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 11:01:19
 */
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { message } from 'antd';
import { StatusCode } from './const';
import cookie from 'react-cookies';

export const NO_NETWORK = '网络未连接, 请检查网络';
const axios = Axios.create({});

// 加入自定义前缀 用于反向代理
// axios.defaults.baseURL = 'http://174.137.50.167:8088/';
axios.defaults.baseURL = '';
interface IRequest {
  params: object;
  responseType: ResponseType;
}

interface ResponseData<T> {
  code: number;
  data: T;
  message: string;
}

let prev = Date.now() - 3000;
const throttleErrors = function (func: any, delay: number) {
  return function () {
    const now = Date.now();
    if (now - prev >= delay) {
      func();
      prev = Date.now();
    }
  };
};

axios.interceptors.request.use(
  (config: AxiosRequestConfig<any>) => {
    const sq = localStorage.getItem('sq') ?? 'Asia/Shanghai';
    if (sq) {
      if (!config.params) {
        config.params = {};
      }
      config.params!.sq = sq;
    }

    const gj = localStorage.getItem('gj');
    if (gj && config.url && config.url?.indexOf('/order/gj') < 0) {
      config.params!.gj = JSON.parse(gj)[1];
    }
    const pay = localStorage.getItem('pay');
    if (pay && config.url && config.url?.indexOf('/order/paytype') < 0) {
      config.params!.payType = pay;
    }
    const token = cookie.load('token');
    if (token) {
      config.headers!.Token = token;
    } else {
      location.href = '/#/login';
    }
    return config;
  },
  (error: AxiosError) => {
    throttleErrors(() => {
      message.error(error.message);
    }, 1000)();
    // 请求 失败，跳转登录
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response: AxiosResponse<ResponseData<any>>) => {
    // 如果没有code 直接返回 如返回的是图片流的情况下
    if (response.status === StatusCode.STATUS_503) {
      return Promise.resolve(response);
    }

    // 请求成功
    if (response.status === StatusCode.STATUS_200) {
      return response.data as any;
    }
    message.error(response.data.message);
    return Promise.reject(new Error(response.data.message));
  },
  (error: AxiosError) => {
    throttleErrors(() => {
      message.error(error.response?.status == 403 ? '登陆失效，重新登陆!' : error.message);
    }, 1000)();
    if (error.response?.status == 403) {
      // 请求 失败，跳转登录
      location.href = '/#/login';
    }
    return Promise.reject(error);
  }
);

// post请求
// @ts-ignore
axios.post = (url: string, params?: object): Promise<any> =>
  axios({
    method: 'post',
    url,
    data: params
  });

// get请求
// @ts-ignore
axios.get = (url: string, param?: IRequest): Promise<any> =>
  axios({
    method: 'get',
    url,
    ...param
  });

export default axios;
