/*
 * @Author: xuyang
 * @Date: 2022-10-16 22:10:21
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-18 00:59:55
 */
export const productUrls = {
  rank: '/order/product' // 排行榜
};
// 商品状态
export const productStatus = {
  up: '上架',
  down: '下架',
  hidden: '隐藏商品'
};
