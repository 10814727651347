/*
 * @Author: xuyang
 * @Date: 2022-10-16 22:10:21
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-16 22:35:06
 */
export const shopUrls = {
  rank: '/shop/allShop', // 排行榜,
  startSync: '/shop/startSync',
  stoptSync: '/shop/stopSync'
};
// 店铺状态
export const shopStatus = {
  hidden: '已停止',
  normal: '正常'
};
