/*
 * @Author: xuyang
 * @Date: 2022-10-16 18:56:41
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-31 21:27:19
 * 趋势分析
 */
import React, { FC, useState, useEffect, useCallback } from 'react';
import { DatePicker, Spin, Table, Button } from 'antd';
import { Line } from '@ant-design/charts';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from './config';
import './index.less';
import axios from '@/utils/request/request';
import { trendUrls } from './const';
import { INormalList } from '../Monitor/type';
import actionTypes from '@/store/actionTypes';
import { connect, useDispatch, useSelector } from 'react-redux';
import { customContent, legend } from '@/utils';
import * as actions from '@/actions';
import BtmInfo from '../Monitor/components/BtmInfo';

const Trend: FC = ({ setStoreData }: any) => {
  const header = useSelector((state: any) => state.header);
  const dispatch = useDispatch();
  // 路由
  const routerParam = useParams();
  const navigate = useNavigate();
  // 类型
  const [type, setType] = useState<string>('shop');
  const [timeValue, setTimeValue] = useState<any>([
    moment().tz(header.timeZone).subtract(14, 'days'),
    moment().tz(header.timeZone)
  ]);
  const [shopChartData, setShopChartData] = useState<Array<INormalList>>([]);
  const [orderChartData, setOrderChartData] = useState<Array<INormalList>>([]);
  const [salesChartData, setSalesChartData] = useState<Array<INormalList>>([]);
  const [visiterChartData, setVisiterChartData] = useState<Array<INormalList>>([]);
  const [shopTableData, setShopTableData] = useState<Array<any>>([]);
  const [orderTableData, setOrderTableData] = useState<Array<any>>([]);
  const [salesTableData, setSalesTableData] = useState<Array<any>>([]);
  const [visiterTableData, setVisiterTableData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // 日期变化
  const timeChange = (value: any) => {
    setTimeValue(value);
  };
  // 获取key值
  const getKeys = (list: any) => Object.keys(list).sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
  // 获取参数
  const getParams = () => ({
    beg: timeValue[0].format('YYYY-MM-DD'),
    end: timeValue[1].format('YYYY-MM-DD')
  });
  // 店铺数据
  const loadShopData = () => {
    setLoading(true);
    axios
      .get(trendUrls.shop, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data.zcdp);
        const keys1 = getKeys(data.zcdp1);
        keys.forEach((item: string, index: number) => {
          if (item === 'total') {
            return;
          }
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.zcdp[item],
            category: '注册店铺数',
            title: item
          });
          as.push({
            type: k,
            value: data?.zcdp1[keys1[index]],
            category: '注册店铺数环比',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.hddp[item],
            category: '活跃店铺数',
            title: item
          });
          as.push({
            type: k,
            value: data?.hddp1[keys1[index]],
            category: '活跃店铺数环比',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.lsdp1[keys1[index]],
            category: '流失店铺环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.lsdp[item],
            category: '流失店铺',
            title: item
          });
          ts.push({
            time: item,
            time1: keys1[index],
            active: data?.hddp[item],
            register: data?.zcdp[item],
            lost: data?.lsdp[item],
            activeCompare: data?.hddp1[keys1[index]],
            registerCompare: data?.zcdp1[keys1[index]],
            lostCompare: data?.lsdp[keys1[index]]
          });
        });
        const tts = ts.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());
        setShopChartData(as);
        setShopTableData([...tts]);
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载订单数量
  const loadOrderData = () => {
    setLoading(true);
    axios
      .get(trendUrls.order, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data?.ddzs);
        const keys1 = getKeys(data?.ddzs1);
        keys.forEach((item: string, index: number) => {
          if (item === 'total') {
            return;
          }
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.ddzs[item],
            category: '订单总数本周期',
            title: item
          });
          as.push({
            type: k,
            value: data?.ddzs1[keys1[index]],
            category: '订单总数环比',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.wzfdd[item],
            category: '未支付订单本周期',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.wzfdd1[keys1[index]],
            category: '未支付订单环比',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.yzfdd[item],
            category: '已支付订单本周期',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.yzfdd1[keys1[index]],
            category: '已支付订单环比',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.zhl1[keys1[index]],
            category: '转化率环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.zhl[item],
            category: '转化率',
            title: item
          });
          ts.push({
            time: item,
            time1: keys1[index],
            paid: data?.yzfdd[item],
            unpaid: data?.wzfdd[item],
            total: data?.ddzs[item],
            paidCompare: data?.yzfdd1[keys1[index]],
            unpaidCompare: data?.wzfdd1[keys1[index]],
            totalCompare: data?.ddzs1[keys1[index]],
            zhl: data?.zhl[item],
            zhl1: data?.zhl1[keys1[index]]
          });
        });
        const tts = ts.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());
        const a = {
          time: '合计',
          time1: '合计环比',
          paid: data?.yzfdd.total,
          unpaid: data?.wzfdd.total,
          total: data?.ddzs.total,
          paidCompare: data?.yzfdd1.total,
          unpaidCompare: data?.wzfdd1.total,
          totalCompare: data?.ddzs1.total,
          zhl: data?.zhl.avg,
          zhl1: data?.zhl1.avg
        };
        setOrderChartData(as);
        setOrderTableData([a, ...tts]);
        return data;
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载销售额数据
  const loadSaleData = () => {
    setLoading(true);
    axios
      .get(trendUrls.sales, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data?.zje);
        const keys1 = getKeys(data?.zje1);
        keys.forEach((item: string, index: number) => {
          if (item === 'total') {
            return;
          }
          const k = item.split('-')[2];
          // as.push({
          //   type: k,
          //   value: data?.zje[item],
          //   category: '总金额',
          //   title: item
          // });
          // as.push({
          //   type: k,
          //   value: data?.zje1[keys1[index]],
          //   category: '总金额本环比',
          //   title: keys1[index]
          // });
          as.push({
            type: k,
            value: data?.wzfje[item],
            category: '未支付金额本',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.wzfje1[keys1[index]],
            category: '未支付金额(上一季同期)',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.yzfje[item],
            category: '已支付金额',
            title: keys[index]
          });
          as.push({
            type: k,
            value: data?.yzfje1[keys1[index]],
            category: '已支付金额(上一季同期)',
            title: keys1[index]
          });
          as.push({
            type: k,
            value: data?.zhl1[keys1[index]],
            category: '转化率环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.zhl[item],
            category: '转化率',
            title: item
          });
          ts.push({
            time: item,
            time1: keys1[index],
            paid: data?.yzfje[item],
            unpaid: data?.wzfje[item],
            total: data?.zje[item],
            paidCompare: data?.yzfje1[keys1[index]],
            unpaidCompare: data?.wzfje1[keys1[index]],
            totalCompare: data?.zje1[keys1[index]],
            zhl: data?.zhl[item],
            zhl1: data?.zhl1[keys1[index]]
          });
        });
        const tts = ts.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());
        const a = {
          time: '合计',
          time1: '合计环比',
          paid: data?.yzfje.total,
          unpaid: data?.wzfje.total,
          total: data?.zje.total,
          paidCompare: data?.yzfje1.total,
          unpaidCompare: data?.wzfje1.total,
          totalCompare: data?.zje1.total,
          zhl: data?.zhl.avg,
          zhl1: data?.zhl1.avg
        };
        console.log([a, ...tts]);
        setSalesChartData(as);
        setSalesTableData([a, ...tts]);
        return data;
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载访客数据
  const loadVisiterData = () => {
    setLoading(true);
    axios
      .get(trendUrls.visiter, { params: getParams() })
      .then((data: any) => {
        setLoading(false);
        const as: Array<INormalList> = [];
        const ts: Array<any> = [];
        const keys = getKeys(data.fk);
        const keys1 = getKeys(data.fk1);
        keys.forEach((item: string, index: number) => {
          if (item === 'total') {
            return;
          }
          const k = item.split('-')[2];
          as.push({
            type: k,
            value: data?.fk[item],
            category: '访客量',
            title: item
          });
          as.push({
            type: k,
            value: data?.fk1[keys1[index]],
            category: '访客环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.jgwc[item],
            category: '加入购物车',
            title: item
          });
          as.push({
            type: k,
            value: data?.jgwc1[keys1[index]],
            category: '加入购物车环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.jrjz[item],
            category: '进入结账',
            title: item
          });
          as.push({
            type: k,
            value: data?.jrjz1[keys1[index]],
            category: '进入结账环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.wcjz[item],
            category: '完成结账',
            title: item
          });
          as.push({
            type: k,
            value: data?.jrjz1[keys1[index]],
            category: '完成结账环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.zhl1[keys1[index]],
            category: '转化率环比',
            title: item
          });
          as.push({
            type: k,
            value: data?.zhl[item],
            category: '转化率',
            title: item
          });
          ts.push({
            time: item,
            time1: keys1[index],
            visiter: data?.fk[item],
            car: data?.jgwc[item],
            enterSettlement: data?.jrjz[item],
            complete: data?.wcjz[item],
            visiterCompare: data?.fk1[keys1[index]],
            carCompare: data?.jgwc1[keys1[index]],
            enterSettlementCompare: data?.jrjz1[keys1[index]],
            completeCompare: data?.wcjz1[keys1[index]],
            zhl: data?.zhl[item],
            zhl1: data?.zhl1[keys1[index]]
          });
        });
        const tts = ts.sort((a, b) => moment(b.time).valueOf() - moment(a.time).valueOf());
        const a = {
          time: '合计',
          time1: '合计环比',
          visiter: data?.fk.total,
          car: data?.jgwc.total,
          enterSettlement: data?.jrjz.total,
          complete: data?.wcjz.avg,
          visiterCompare: data?.fk1.total,
          carCompare: data?.jgwc1.total,
          enterSettlementCompare: data?.jrjz1.total,
          completeCompare: data?.wcjz1.avg,
          zhl: data?.zhl.avg,
          zhl1: data?.zhl1.avg
        };
        setVisiterChartData(as);
        setVisiterTableData([a, ...tts]);
      })
      .catch(function () {
        setLoading(false);
      });
  };
  // 加载数据
  const loadData = () => {
    if (type === 'shop') {
      loadShopData();
    } else if (type === 'order') {
      loadOrderData();
    } else if (type === 'sales') {
      loadSaleData();
    } else if (type === 'visiter') {
      loadVisiterData();
    }
    return null;
  };
  useEffect(() => {
    // console.log(routerParam);
    const t = routerParam.type;
    if (!!t) {
      setType(t);
    } else {
      setType('shop');
    }
  }, [routerParam]);
  //  日期变化
  const dispatchTitle = useCallback(
    (title: string) => dispatch({ type: actionTypes.SET_TITLE, params: title }),
    [dispatch]
  );
  // 保存store日期
  const dispatchTime = useCallback(
    (time: string) => dispatch({ type: actionTypes.SET_TIME, params: time }),
    [dispatch]
  );
  useEffect(() => {
    loadData();
    dispatchTitle(config[type].title);
  }, [type]);
  useEffect(() => {
    loadData();
  }, [timeValue]);
  // 获取table数据
  const getChartsData = () => {
    if (type === 'shop') {
      return shopChartData;
    } else if (type === 'order') {
      return orderChartData;
    } else if (type === 'sales') {
      return salesChartData;
    } else if (type === 'visiter') {
      return visiterChartData;
    }
    return [];
  };
  const chartConfig = {
    data: getChartsData(),
    xField: 'type',
    yField: 'value',
    legend: { ...legend },
    seriesField: 'category',
    tooltip: {
      customContent: customContent
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
      }
    }
  };
  // 查看
  const onCheck = (time: string) => {
    // setStoreData(actionTypes.SET_TIME, time);
    dispatchTime(time);
    navigate('/shopRank');
  };
  // 获取列数据
  const getColumns = () => {
    if (type === 'shop') {
      const cl = [
        ...config[type].columns,
        {
          key: 'action',
          title: '操作',
          align: 'right',
          render: (txt: any, record: any) => {
            return (
              <Button type="link" onClick={() => onCheck(record?.time)}>
                查看
              </Button>
            );
          }
        }
      ];
      return cl;
    }
    return config[type].columns;
  };
  // 获取table数据
  const getTableData = () => {
    if (type === 'shop') {
      return shopTableData;
    } else if (type === 'order') {
      return orderTableData;
    } else if (type === 'sales') {
      return salesTableData;
    } else if (type === 'visiter') {
      return visiterTableData;
    }
    return [];
  };
  return (
    <Spin spinning={loading}>
      <div className="trend-main">
        <div className="trend-search">
          <DatePicker.RangePicker onChange={timeChange} placeholder={['开始日期', '结束日期']} value={timeValue} />
        </div>
        <div className="trend-chart">
          <p className="trend-title">趋势分析</p>
          <div className="trend-chart-main">
            <div>
              <Line {...chartConfig}></Line>
            </div>
          </div>
        </div>
        <div className="trend-table">
          <p className="trend-title">数据报告</p>
          <div className="trend-table-content">
            <Table rowKey="time" columns={getColumns()} dataSource={getTableData()}></Table>
          </div>
        </div>
        {type === 'order' && <BtmInfo timeValue={timeValue} />}
      </div>
    </Spin>
  );
};
export default connect((state) => state, actions)(Trend);
