/*
 * @Author: xuyang
 * @Date: 2022-10-25 23:33:46
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-25 23:41:22
 */
const headerTypes = {
  SET_TITLE: 'SET_TITLE',
  SET_TIME_ZONE: 'SET_TIME_ZONE'
};
export default headerTypes;
