import actionTypes from '@/store/actionTypes';

// import { createAction } from 'redux-actions';

// export const setTitle = (title: string) => createAction(actionTypes.SET_TITLE, () => title);
export const setStoreData = (type: string, payload: any): object => {
  if (!actionTypes[type] && !actionTypes[type]) throw new Error('请传入修改的数据类型和值');
  return { type, payload };
};
/**
 * 设置timezone
 * @param timeZone
 * @returns
 */
export const setActionData = (type: string, payload: any) => {
  return {
    type: type,
    payload
  };
};
