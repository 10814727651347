/*
 * @Descripttion:
 * @version:
 * @Author: xuyang
 * @Date: 2022-09-24 09:29:21
 * @LastEditors: xuyang
 * @LastEditTime: 2022-09-24 10:37:21
 */
import { combineReducers } from 'redux';
import header from './header';
import shop from './shop';

const rootReducer = combineReducers({
  header,
  shop
});
export default rootReducer;
