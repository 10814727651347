import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Button, Table } from 'antd';
import { Line, Pie } from '@ant-design/charts';
import { ICharts } from './types';
import axios from '@/utils/request/request';
import './index.less';
import ig from '@/asset/img/icon-07.png';
const AllOrders: FC = () => {
  const [totalData, setTotalData] = useState<Array<ICharts>>([]);
  const [pieData, setPieData] = useState<Array<ICharts>>([]);
  const [tableData, setTableData] = useState<Array<any>>([]);
  // 图表配置
  const config = {
    data: totalData,
    xField: 'year',
    yField: 'value',
    xAxis: {
      // type: 'timeCat',
      tickCount: 5
    }
  };
  // 饼图配置
  const pieConfig = {
    appendPadding: 10,
    data: pieData,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    label: {
      type: 'inner',
      offset: '-50%',
      content: '{value}',
      style: {
        textAlign: 'center',
        fontSize: 14
      }
    },
    interactions: [
      {
        type: 'element-selected'
      },
      {
        type: 'element-active'
      }
    ],
    statistic: {
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        content: 'AntV\nG2Plot'
      }
    }
  };
  const loadSummaryData = () => {
    axios.get('/summary').then((data: any) => {
      setPieData(data.content);
      return;
    });
  };
  // 加载数据
  useEffect(() => {
    loadSummaryData();
    axios.get('http://localhost:9000/allOrders').then((data: any) => {
      setTotalData(data.content);
      return;
    });
    axios.get('http://localhost:9000/tableData').then((data: any) => {
      setTableData(data.content);
      return;
    });
  }, []);
  const columns = [
    {
      key: 'rank',
      title: '排名',
      dataIndex: 'rank'
    },
    {
      key: 'img',
      title: '',
      dataIndex: 'img',
      render: () => <img src={ig} />
    },
    {
      key: 'name',
      title: '产品名称',
      dataIndex: 'name'
    },
    {
      key: 'num',
      title: '售出数量',
      dataIndex: 'num'
    }
  ];
  return (
    <div className="all-products">
      <Row>
        <Col span="24">
          <Button>近一周</Button>
        </Col>
      </Row>
      <Row gutter={45}>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">总销售额</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-weight">USD 6762138.04</p>
            </div>
            <div className="chart">
              <p className="chart-tip">按时间段显示销售额</p>
              <Line {...config} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">访客统计</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-normal">访客：41</p>
              <p className="font-normal">新访客：30</p>
              <p className="font-normal">次数：2456</p>
            </div>
            <div className="chart">
              <p className="chart-tip">按时间段显示销售额</p>
              <Line {...config} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">平均订单金额</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-weight">USD 6762138.04</p>
            </div>
            <div className="chart">
              <p className="chart-tip">按时间段显示销售额</p>
              <Line {...config} />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={45}>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">总订单数</span>
              <Button className="btn-check" type="link">
                查看报告
              </Button>
            </div>
            <div className="info">
              <p className="font-weight">190</p>
            </div>
            <div className="chart">
              <p className="chart-tip">按时间段显示销售额</p>
              <Line {...config} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">复购率</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-weight">98%</p>
            </div>
            <div className="chart">
              <p className="chart-tip">按时间段显示销售额</p>
              <Line {...config} />
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">按平台访客</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="chart">
              <Pie {...pieConfig} />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={45}>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">按国家访客</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-normal">全部</p>
              <p className="font-weight">41 100%</p>
            </div>
            <div className="chart">
              <div className="list-content">
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">荷兰</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">阿拉伯联合酋长国</span>
                  <span className="list-it2">12</span>
                  <span className="list-it3">100%</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col span={8}>
          <div className="product-panel">
            <div className="top">
              <span className="title">访客转化率</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-normal">全部 41</p>
            </div>
            <div className="chart">
              <div className="list-content">
                <div className="list-item">
                  <span className="list-it1">加入购物车人数</span>
                  <span className="list-it4">1656.03%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">结账人数</span>
                  <span className="list-it4">1656.03%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">进入确认地址人数</span>
                  <span className="list-it4">1656.03%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">进入确认快捷支付人数</span>
                  <span className="list-it4">1656.03%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">进入支付页面人数</span>
                  <span className="list-it4">1656.03%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">支付成功人数</span>
                  <span className="list-it4">1656.03%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="product-panel">
            <div className="top">
              <span className="title">按网站来源访客</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="info">
              <p className="font-normal">全部 41</p>
            </div>
            <div className="chart">
              <div className="list-content">
                <div className="list-item">
                  <span className="list-it1">直接访问</span>
                  <span className="list-it2">40</span>
                  <span className="list-it3">1656.03%</span>
                </div>
                <div className="list-item">
                  <span className="list-it1">百度</span>
                  <span className="list-it2">40</span>
                  <span className="list-it3">1656.03%</span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={45}>
        <Col span={16}>
          <div className="product-panel">
            <div className="top">
              <span className="title">热销产品（按售出产品位数）</span>
              <Button type="link">查看报告</Button>
            </div>
            <div className="chart">
              <Table columns={columns} dataSource={tableData}></Table>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AllOrders;
