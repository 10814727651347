/*
 * @Author: xuyang
 * @Date: 2022-10-16 20:05:18
 * @Last Modified by: xuyang
 * @Last Modified time: 2022-10-31 20:21:06
 */
import { formatZHL } from '@/utils/index';
export const config = {
  shop: {
    title: '店铺趋势分析',
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time',
        render: (_txt, record) => {
          return (
            <div>
              <p className="curr">{record.time}</p>
              <p className="ratio">{record.time1}</p>
            </div>
          );
        }
      },
      {
        key: 'active',
        title: '活跃店铺',
        dataIndex: 'active',
        align: 'right',
        render: (_txt, record) => {
          return (
            <div>
              <p className="curr">{record.active}</p>
              <p className="ratio">{record.activeCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'register',
        title: '注册店铺数',
        align: 'right',
        dataIndex: 'register',
        render: (_txt, record) => {
          return (
            <div>
              <p className="curr">{record.register}</p>
              <p className="ratio">{record.registerCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'lost',
        title: '流失店铺数',
        align: 'right',
        dataIndex: 'lost',
        render: (_txt, record) => {
          return (
            <div>
              <p className="curr">{record.lost}</p>
              <p className="ratio">{record.lost}</p>
            </div>
          );
        }
      }
    ]
  },
  order: {
    title: '订单趋势分析',
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.time}</p>
              <p className="ratio">{record.time1}</p>
            </div>
          );
        }
      },
      {
        key: 'paid',
        title: '已支付订单',
        dataIndex: 'paid',
        align: 'right',
        render(_, record) {
          return (
            <div>
              <p className="curr"> {record.paid}</p>
              <p className="ratio">{record.paidCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'unpaid',
        title: '未支付订单',
        align: 'right',
        dataIndex: 'unpaid',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.unpaid}</p>
              <p className="ratio">{record.unpaidCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'total',
        title: '订单总数',
        align: 'right',
        dataIndex: 'total',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.total}</p>
              <p className="ratio">{record.totalCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'zhl',
        title: '转化率',
        align: 'right',
        dataIndex: 'zhl',
        render(_, record) {
          return (
            <div>
              <p className="curr">{formatZHL(record.zhl)}</p>
              <p className="ratio">{formatZHL(record.zhl1)}</p>
            </div>
          );
        }
      }
      // {
      //   key: 'paidCompare',
      //   title: '已支付订单(环比)',
      //   dataIndex: 'paidCompare'
      // },
      // {
      //   key: 'unpaidCompare',
      //   title: '未支付订单(环比)',
      //   dataIndex: 'unpaidCompare'
      // },
      // {
      //   key: 'totalCompare',
      //   title: '订单总数(环比)',
      //   dataIndex: 'totalCompare'
      // }
    ]
  },
  sales: {
    title: '销量趋势分析',
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.time}</p>
              <p className="ratio">{record.time1}</p>
            </div>
          );
        }
      },
      {
        key: 'paid',
        title: '已支付订单',
        dataIndex: 'paid',
        align: 'right',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.paid}</p>
              <p className="ratio">{record.paidCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'unpaid',
        title: '未支付订单',
        dataIndex: 'unpaid',
        align: 'right',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.unpaid}</p>
              <p className="ratio">{record.unpaidCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'total',
        title: '订单总数',
        dataIndex: 'total',
        align: 'right',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.total}</p>
              <p className="ratio">{record.totalCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'zhl',
        title: '转化率',
        align: 'right',
        dataIndex: 'zhl',
        render(_, record) {
          return (
            <div>
              <p className="curr">{formatZHL(record.zhl)}</p>
              <p className="ratio">{formatZHL(record.zhl1)}</p>
            </div>
          );
        }
      }
      // {
      //   key: 'paidCompare',
      //   title: '已支付金额(环比)',
      //   dataIndex: 'paidCompare'
      // },
      // {
      //   key: 'unpaidCompare',
      //   title: '未支付金额(环比)',
      //   dataIndex: 'unpaidCompare'
      // },
      // {
      //   key: 'totalCompare',
      //   title: '总金额(环比)',
      //   dataIndex: 'totalCompare'
      // }
    ]
  },
  visiter: {
    title: '访客数据分析',
    columns: [
      {
        key: 'time',
        title: '时间',
        dataIndex: 'time',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.time}</p>
              <p className="ratio">{record.time1}</p>
            </div>
          );
        }
      },
      {
        key: 'visiter',
        title: '访客',
        align: 'right',
        dataIndex: 'visiter',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.visiter}</p>
              <p className="ratio">{record.visiterCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'car',
        title: '加入购物车',
        align: 'right',
        dataIndex: 'car',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.car}</p>
              <p className="ratio">{record.carCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'enterSettlement',
        title: '进入结账',
        align: 'right',
        dataIndex: 'enterSettlement',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.enterSettlement}</p>
              <p className="ratio">{record.enterSettlementCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'complete',
        title: '完成结账',
        align: 'right',
        dataIndex: 'complete',
        render(_, record) {
          return (
            <div>
              <p className="curr">{record.complete}</p>
              <p className="ratio">{record.completeCompare}</p>
            </div>
          );
        }
      },
      {
        key: 'zhl',
        title: '转化率',
        dataIndex: 'zhl',
        align: 'right',
        render(_, record) {
          return (
            <div>
              <p className="curr">{formatZHL(record.zhl)}</p>
              <p className="ratio">{formatZHL(record.zhl1)}</p>
            </div>
          );
        }
      }
      // {
      //   key: 'visiterCompare',
      //   title: '访客(环比)',
      //   dataIndex: 'visiterCompare'
      // },
      // {
      //   key: 'carCompare',
      //   title: '加入购物车(环比)',
      //   dataIndex: 'carCompare'
      // },
      // {
      //   key: 'enterSettlement',
      //   title: '进入结账(环比)',
      //   dataIndex: 'enterSettlementCompare'
      // },
      // {
      //   key: 'completeCompare',
      //   title: '完成结账(环比)',
      //   dataIndex: 'completeCompare'
      // }
    ]
  }
};
